import React from "react";
import { graphql } from "gatsby";

import CONTACT from "../../../content/contact.json";

import { useTranslations } from "../../hooks/translations";
import SEO from "../../components/seo";
import MapComponent from "../../components/about/map-component";

import styles from "./contacts.module.scss";
import SocialLinks from "../../components/social-links";

export const query = graphql`
  query ContactsQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        about {
          contacts
        }
      }
      ...LayoutTranslations
    }
  }
`;

const ContactsPage = () => {
  const t = useTranslations("pages.about.contacts");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <section className={styles.contacts}>
        <div className="bp-container">
          <h1>{t.heading}</h1>

          <div className="bp-cards bp-2">
            <div className="bp-card">
              <h2>
                {t.companyTitle}
                <br />
                {t.companyName}
              </h2>
              <p>
                {t.companyCode}
                :&nbsp;
                {CONTACT.companyCode}
              </p>
              <p>
                {t.address}
                :&nbsp;
                {t.companyAddress}
              </p>
            </div>

            <div className="bp-card">
              <h2>{t.contactUs}</h2>
              <p>
                <a href={`tel:${CONTACT.phone}`} className="bp-link">
                  {CONTACT.phone}
                </a>
              </p>
              <p>
                <a href={`mailto:${CONTACT.email}`} className="bp-link">
                  {CONTACT.email}
                </a>
              </p>
              <SocialLinks />
            </div>
          </div>
        </div>
      </section>

      <MapComponent />
    </>
  );
};

export default ContactsPage;
