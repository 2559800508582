import { useEffect } from "react";
import { loadGoogleMaps } from "../api/maps";

export const useMaps = callback => {
  if (typeof window === "undefined") {
    return;
  }

  useEffect(() => {
    if (!window.google) {
      loadGoogleMaps(callback);
    } else {
      callback();
    }
  }, []);
};
