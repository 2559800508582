import React, { useRef } from "react";

import CONTACT from "../../../content/contact.json";

import { useMaps } from "../../hooks/maps";

import styles from "./map-component.module.scss";

const MapComponent = () => {
  const ref = useRef();

  useMaps(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: CONTACT.addressCoordinates,
      zoom: 15
    });

    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      map,
      position: CONTACT.addressCoordinates
    });
  });

  return <section className={styles.container} ref={ref} />;
};

export default MapComponent;
